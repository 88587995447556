.slide-container {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.slide-container h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  text-transform: uppercase;
  color: #343434;
}

.slide-container h1 em {
  font-style: unset;
  color: #87D9D9;
}

.slide-container p {
  color: #343434;
}

.slide-image-box img {
  border-radius: 20px;
  object-fit: cover;
  height: 45vh;
}

.slide-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.slide-left .slide-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  text-align: right;
}

.slide-left .slide-image-box img {
  width: 40vw;
}

.slide-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}

.slide-center .slide-text-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 800px;
  gap: 30px;
}

.slide-center .slide-image-box {
  display: flex;
  justify-content: center;
}

.slide-center .slide-image-box img {
  height: unset;
  width: 65vw;
}

.slide-right {
  display: flex;
  flex-direction: row-reverse;
  gap: 30px;
  justify-content: center;
}

.slide-right .slide-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.slide-right .slide-image-box img {
  width: 40vw;
}

@media screen and (max-width:1400px) {
  .slide {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slide .slide-image-box {
    display: flex;
    justify-content: center;
    object-fit: cover;
  }

  .slide-image-box img {
    height: unset;
  }

  .slide-left .slide-image-box img {
    width: 65vw;
  }

  .slide-center .slide-image-box img {
    width: 65vw;
  }

  .slide-right .slide-image-box img {
    width: 65vw;
  }

  .slide .slide-text-content {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .slide-container h1 {
    font-size: 1.3rem;
  }
}